a, a:visited, a:hover, a:active {
    color: #008cba;
}

.tag {
    color: #008cba;
}

body {
    font-family: "Open Sans", Helvetica, Roboto, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
}
